import React, { ReactElement, ReactNodeArray } from 'react'
import styles from './index.module.scss'
import { Icon } from '../../Icon'
import classNames from 'classnames'

interface IErrorProps {
  message: string | JSX.Element | ReactNodeArray
}

export const Error = ({ message }: IErrorProps): ReactElement => {
  return (
    <div
      data-testid="form-error"
      role="alert"
      aria-live="assertive"
      className={classNames(styles.error__container, 'mb-7')}
    >
      <div className={styles.error}>
        <Icon icon="info" variant="light" />
        <p>{message}</p>
      </div>
    </div>
  )
}
