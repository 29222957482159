import { SignIn } from 'components/Form/Forms/SignIn'
import { Navigation } from 'components/Navigation'
import { PAGE_CONFIG } from 'configuration/analytics'
import { navigationLinks } from 'configuration/routes'
import { usePageTitle } from 'hooks/usePageTitle'
import { TranslationKeyPrefix } from 'hooks/useTranslations'
import Head from 'next/head'
import { ReactElement, useEffect } from 'react'
import SessionProvider from 'session'
import { logService } from 'utils/logService'

const { SIGN_IN } = PAGE_CONFIG

SessionProvider.clearEntryPoint()

interface IAuthFlowProps {
  language: string
}

const AuthFlow = (props: IAuthFlowProps): ReactElement => {
  const { language } = props
  const pageTitle = usePageTitle(TranslationKeyPrefix.SignIn)

  useEffect(() => {
    logService.trackPage({
      language,
      details: SIGN_IN,
    })
  }, [language])

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Navigation links={navigationLinks} />
      <div className="mt-7 mt-lg-11">
        <SignIn />
      </div>
    </>
  )
}

AuthFlow.getInitialProps = () => {
  return {
    useMaximalHeader: true,
    useMaximalFooter: true,
    preloadLabelPrefixes: [
      TranslationKeyPrefix.SignIn,
      TranslationKeyPrefix.AccountCreation,
      TranslationKeyPrefix.Input,
    ],
  }
}

export default AuthFlow
