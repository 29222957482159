import actions from 'actions/authentication'
import { Button } from 'components/Button'
import { Error } from 'components/Form/Error'
import { FormSubmitErrorTracker } from 'components/Form/FormSubmitErrorTracker'
import { Input } from 'components/Form/Input'
import { PAGE_CONFIG } from 'configuration/analytics'
import { Form, Formik, useFormikContext } from 'formik'
import {
  TranslationKeyPrefix,
  TranslationLiteralFunction,
  useTranslations,
} from 'hooks/useTranslations'
import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IAppState } from 'reducers'
import * as yup from 'yup'
import { environment } from 'configuration'
import { useFormikErrorCount } from 'hooks/useFormikErrorCount'

const validationRules = (tl: TranslationLiteralFunction) =>
  yup.object().shape({
    email: yup.string().email(tl('email.invalid')).required(tl('email.required')),
    password: yup.string().required(tl('password.required')),
  })

interface ISignInProps {
  onClick?: () => void
}

interface SignInFormFields {
  email: string
  password: string
}

const InnerForm = () => {
  const loading = useSelector((state: IAppState) => state?.authentication?.loading)

  const {
    getTranslation: t,
    getTranslationLiteral: tl,
    getUrlByLinkKey: ublk,
    getTextByLinkKey: tblk,
  } = useTranslations(TranslationKeyPrefix.SignIn)

  const { handleSubmit, values, errors, touched, handleBlur, handleChange, submitForm } =
    useFormikContext<SignInFormFields>()

  const { message: error } = useFormikErrorCount<SignInFormFields>()

  // In order to hide the username (an email address) from the URL,
  // the Forgot password page is opened by submitting a generated form using the POST method.
  // https://christiestech.atlassian.net/browse/TW2-2896
  const openForgotPasswordPage = (email: string): void => {
    const postForm = document.createElement('form')
    postForm.method = 'POST'
    postForm.action = `${environment.dotComUrl}${ublk(tl('password.forgot'))}`
    postForm.target = '_blank'
    postForm.className = 'visually-hidden'
    postForm.ariaHidden = 'true'
    postForm.innerHTML = `<input type="hidden" name="email" value="${email}" />`
    document.body.appendChild(postForm)
    postForm.submit()
  }

  return (
    <Form>
      {error && (
        <div className="mt-4">
          <Error message={error} />
        </div>
      )}
      <Input
        classname="mt-5"
        label={tl('email.label')}
        name="email"
        type="email"
        value={values['email']}
        error={errors['email'] || ''}
        touched={touched['email'] || false}
        onChange={(e: any) => {
          handleChange(e)
        }}
        onEnterPressed={submitForm}
        onBlur={handleBlur}
        maxLength={50}
      />

      <Input
        classname="mt-5"
        label={tl('password.label')}
        name="password"
        type="password"
        value={values['password']}
        error={errors['password'] || ''}
        touched={touched['password'] || false}
        onChange={(e: any) => {
          handleChange(e)
        }}
        onEnterPressed={submitForm}
        onBlur={handleBlur}
      />

      <div className="mt-2 text-align-right">
        <Button
          type="button"
          link
          buttonText={tblk(tl('password.forgot'))}
          className="chr-action-bold"
          onClick={() => openForgotPasswordPage(values.email)}
          testId="forgot-password"
        />
      </div>

      <div>
        <Button
          loading={loading}
          className="mt-6"
          type="button"
          onClick={handleSubmit}
          buttonText={tl('button.continue')}
        />
      </div>

      <hr className="mt-5" aria-hidden="true" />

      <div className="mt-5">
        <p>{t('create_account.prompt')}</p>
      </div>

      <FormSubmitErrorTracker details={PAGE_CONFIG.SIGN_IN} />
    </Form>
  )
}

export const SignIn = ({ onClick }: ISignInProps): ReactElement => {
  const dispatch = useDispatch()
  const error = useSelector((state: IAppState) => state?.authentication?.error)

  const { getTranslation: t, getTranslationLiteral: tl } = useTranslations(
    TranslationKeyPrefix.SignIn
  )

  return (
    <div
      className="container-fluid text-align-center"
      data-testid="form"
      data-analytics-region="signin-form"
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-4">
          <h1 data-testid="login-header" className="chr-heading-l-serif">
            {t('header')}
          </h1>
          {error && (
            <div className="mt-4">
              <Error message={t(error)} />
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 offset-md-4">
          <Formik
            validationSchema={validationRules(tl)}
            initialValues={{ password: '', email: '' }}
            onSubmit={(values) => {
              if (onClick) {
                onClick()
              }
              dispatch(actions.resetErrorAction())
              dispatch(
                actions.userAuthenticateAction({
                  ...values,
                })
              )
            }}
          >
            <InnerForm />
          </Formik>
        </div>
      </div>
    </div>
  )
}
